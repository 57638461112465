@import '../../../styles/variables';

.allTabLinks {
  &:hover {
    cursor: pointer;
  }

  @include media-breakpoint-down(xs) {
    padding-left: 0.85rem !important;
    padding-right: 0.85rem !important;
  }
}
.datepicker {
  width: 9.625rem;
  border-color: #ced4da;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
}
