@import '../../../../../styles/variables';

.highlight {
  background-color: transparent;
  color: $highlightColor;
}

.disabledCompareBtn {
  cursor: default;
}
