.input {
  width: 15.625rem;
}

.alert {
  max-width: 15.625rem;
}

.idInput {
  width: 7.313rem;
}
