html,
body,
#root {
  min-height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

// Required
@import 'styles/variables';

// Optional
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/bootstrap-reboot';

// Styling for toasts
@import '~react-toastify/dist/ReactToastify.css';

// Styling for date picker
@import 'react-datepicker/dist/react-datepicker.css';
