@import '../../styles/variables';

.loginModal {
  z-index: $zindex-login-modal;
}

.modalBackdrop {
  background-image: url('../../assets/beam_center.png'), linear-gradient(to bottom, $navy, $primary);
  background-size: auto 900px, cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1 !important;
  z-index: $zindex-login-modal-backdrop;

  @include media-breakpoint-down(sm) {
    background-size: auto 700px, cover;
  }

  @include media-breakpoint-down(xs) {
    background-size: auto 600px, cover;
  }
}
