@import '../../styles/variables';

.pageInput {
  width: 5rem !important;
}

.rowSelectDropdown {
  width: unset !important;
}

.tableButtons {
  width: 5rem !important;

  @include media-breakpoint-down(xs) {
    width: unset !important;
  }
}
