@import './../../styles/variables';

.header {
  font-size: calc(0.5rem + 0.5vw);
  padding: 80px !important;

  a {
    color: $white;
    padding: 5px;
  }
}

.activeHeaderLink {
  font-weight: bold !important;
  background-color: $gray-200 !important;
  color: $primary !important;
}

.menu {
  flex-shrink: 0;
  background-color: $gray-200;
  font-size: calc(0.75rem + 0.5vw);

  a {
    color: $primary !important;
    padding: 5px;
  }
}

.activeNavLink {
  font-weight: bold !important;
}

.secondnav {
  background-color: $gray-200;
}
